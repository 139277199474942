<template>
  <div class="banner">

    <div class="banner-content">
      <div class="special-tag">
        <i class="fas fa-sparkles pulse-animation"></i>
        <span>Welcome</span>
        <i class="fas fa-sparkles pulse-animation"></i>
      </div>

      <h2>Discover Your Next Adventure</h2>
      <p>Join our community of passionate readers and storytellers</p>

      <div class="feature-highlights">
        <div class="highlight-item">
          <i class="fas fa-fire"></i>
          <span>Trending Now</span>
        </div>
        <div class="highlight-item">
          <i class="fas fa-bookmark"></i>
          <span>Must Reads</span>
        </div>
        <div class="highlight-item">
          <i class="fas fa-crown"></i>
          <span>Featured Picks</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeBanner'
}
</script>

<style scoped>
.banner {
  background: linear-gradient(135deg, rgba(69, 41, 137, 0.95) 0%, rgba(81, 69, 145, 0.95) 100%);
  padding: 4rem 1.6rem;
  position: relative;
  overflow: hidden;
  min-height: 320px;
  margin-bottom: 3.2rem;
  backdrop-filter: blur(4px);
}


.banner-content {
  position: relative;
  z-index: 1;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  color: white;
}

.special-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  margin-bottom: 1.2rem;
  color: #f27cf9;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

h2 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  color: white;
}

p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2.4rem;
  font-weight: 400;
}

.feature-highlights {
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  margin-top: 1.6rem;
}

.highlight-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  padding: 0.8rem;
  transition: transform 0.2s ease;
}

.highlight-item:hover {
  transform: translateY(-2px);
}

.highlight-item i {
  font-size: 1.4rem;
  color: #f27cf9;
}

.highlight-item span {
  color: white;
  font-weight: 500;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-24px);
  }
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
}

.pulse-animation {
  animation: pulse 2s ease-in-out infinite;
}

@media (max-width: 768px) {
  .banner {
    padding: 4rem 1.6rem;
    min-height: 240px;
  }

  .floating-icon {
    font-size: 9rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.9rem;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 1.8rem;
  }

  .feature-highlights {
    flex-direction: column;
    gap: 1.2rem;
  }

  .highlight-item i {
    font-size: 1.2rem;
  }

  .special-tag {
    gap: 0.3rem;
    margin-bottom: 0.9rem;
  }
}
</style>
