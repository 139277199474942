<template>
  <div class="book-list-card">
    <div class="book-image">
      <img :src="getBookCoverUrl" :alt="book.title" @error="e => e.target.src = '/placeholder.jpg'" />
      <button @click.prevent="handleCartAction" class="quick-add" :class="{ 'in-cart': cartStatus.exists }">
        <i :class="cartStatus.exists ? 'fas fa-check' : 'fas fa-plus'"></i>
      </button>
    </div>

    <div class="book-content">
      <div class="book-header">
        <div class="book-info">
          <div class="rating">
            <i v-for="n in 5" :key="n" class="fas fa-star" :class="{ filled: n <= Math.floor(book.rating || 0) }"></i>
            <span class="review-count">{{ book.reviews_count || 0 }} reviews</span>
          </div>
          <router-link :to="`/books/${book.id}`" class="book-title">{{ book.title }}</router-link>
          <p class="author">{{ book.author.name }}</p>
          <div class="genres">
            <span v-for="genre in book.genres" :key="genre.id" class="genre-tag">
              {{ genre.name }}
            </span>
          </div>
        </div>

        <div class="price-area">
          <div class="price">Rs {{ formatPrice(book.price) }}</div>
        </div>
      </div>

      <div class="cart-button-area">
        <button @click.prevent="handleCartAction" class="add-cart" :class="{ 'in-cart': cartStatus.exists }">
          {{ cartStatus.exists ? 'In Cart' : 'Add to Cart' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import api, { getAssetUrl } from '@/api/axios'

export default {
  name: 'BookCardWithCartListView',
  props: {
    book: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const toast = useToast()
    const cartStatus = ref({
      exists: false,
      cart_item_id: null,
      purchase_type: null,
      quantity: 0,
      rental_period: null
    })

    const formatPrice = (price) => {
      return Number(price).toFixed(2)
    }

    const getBookCoverUrl = computed(() => {
      return getAssetUrl(props.book.cover_image)
    })

    const checkCartStatus = async () => {
      try {
        if (!store.getters.isAuthenticated) return

        const response = await api.get(`/cart/${props.book.id}/check`, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          }
        })

        if (response.data.success) {
          cartStatus.value = response.data.data
        }
      } catch (error) {
        console.error('Error checking cart status:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
          cartStatus.value.exists = false
        }
      }
    }

    const removeFromCart = async () => {
      try {
        if (!cartStatus.value.cart_item_id) return

        const response = await api.delete(
          `/cart/${cartStatus.value.cart_item_id}/remove`,
          {
            headers: {
              Authorization: `Bearer ${store.getters.token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.success) {
          cartStatus.value.exists = false
          cartStatus.value.cart_item_id = null
          toast.success('Removed from cart successfully', {
            timeout: 2000,
            position: 'top-center',
            icon: 'fas fa-check-circle',
          })
          store.commit('updateCart', response.data.cart)
        }
      } catch (error) {
        console.error('Error removing from cart:', error)
        toast.error('Failed to remove from cart', {
          timeout: 2000,
          position: 'top-center',
          icon: 'fas fa-exclamation-circle',
        })
        if (error.response?.status === 401) {
          store.commit('logout')
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
        }
      }
    }

    const addToCart = async () => {
      try {
        if (!store.getters.isAuthenticated) {
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
          return
        }

        const response = await api.post(
          `/cart/${props.book.id}/add`,
          {
            purchase_type: "buy"
          },
          {
            headers: {
              Authorization: `Bearer ${store.getters.token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.success) {
          store.commit('updateCart', response.data.cart)
          await checkCartStatus()
          toast.success('Added to cart successfully', {
            timeout: 2000,
            position: 'top-center',
            icon: 'fas fa-check-circle',
          })
        }
      } catch (error) {
        console.error('Error adding to cart:', error)

        if (error.response?.status === 400) {
          toast.error('Item is out of stock', {
            timeout: 2000,
            position: 'top-center',
            icon: 'fas fa-exclamation-circle',
          })
        } else if (error.response?.status === 401) {
          store.commit('logout')
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
        } else {
          toast.error('Failed to add to cart', {
            timeout: 2000,
            position: 'top-center',
            icon: 'fas fa-exclamation-circle',
          })
        }
      }
    }

    const handleCartAction = async () => {
      if (cartStatus.value.exists) {
        await removeFromCart()
      } else {
        await addToCart()
      }
    }

    onMounted(() => {
      checkCartStatus()
    })

    return {
      cartStatus,
      formatPrice,
      handleCartAction,
      getBookCoverUrl,
    }
  }
}
</script>

<style scoped>
.book-list-card {
  display: flex;
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  gap: 2rem;
  box-shadow: var(--shadow);
  transition: transform 0.2s ease;
  position: relative;
}

.book-list-card:hover {
  transform: translateY(-4px);
}

.book-image {
  width: 200px;
  min-width: 200px;
  position: relative;
}

.book-image img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 8px;
}

.quick-add {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: #f27cf9;
  color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
  z-index: 2;
}

.quick-add.in-cart {
  opacity: 1;
  background-color: #4CAF50;
}

.book-list-card:hover .quick-add {
  opacity: 1;
}

.book-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.book-header {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.book-info {
  flex: 1;
}

.rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.stars {
  color: #ddd;
}

.stars .filled {
  color: #ffd700;
}

.review-count {
  font-size: 0.8rem;
  color: var(--text-light);
}

.book-title {
  display: block;
  font-size: 1.5rem;
  color: #282772;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.author {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.price-area {
  text-align: right;
}

.price {
  font-size: 1.5rem;
  font-weight: 600;
  color: #f27cf9;
}

.cart-button-area {
  position: absolute;
  bottom: 0;
  right: 0;
}

.add-cart {
  background-color: #f27cf9;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-cart.in-cart {
  background-color: #4CAF50;
}

.genres {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.genre-tag {
  background: #f8f9fa;
  color: var(--text-light);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
}

@media screen and (max-width: 768px) {
  .book-list-card {
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
  }

  .book-image {
    width: 100px;
    min-width: 100px;
  }

  .book-image img {
    height: 140px;
  }

  .quick-add {
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  .book-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .book-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .author {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }

  .rating {
    margin-bottom: 0.5rem;
  }

  .rating .fa-star {
    font-size: 0.8rem;
  }

  .review-count {
    font-size: 0.7rem;
  }

  .price-area {
    text-align: left;
  }

  .price {
    font-size: 1.1rem;
  }

  .cart-button-area {
    position: static;
    margin-top: 0.5rem;
  }

  .add-cart {
    width: 100%;
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .genres {
    margin-top: 0.25rem;
    gap: 0.25rem;
  }

  .genre-tag {
    padding: 0.15rem 0.5rem;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 480px) {
  .book-list-card {
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .book-image {
    width: 80px;
    min-width: 80px;
  }

  .book-image img {
    height: 120px;
  }

  .book-title {
    font-size: 0.9rem;
  }

  .author {
    font-size: 0.75rem;
  }

  .price {
    font-size: 1rem;
  }

  .genres {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
