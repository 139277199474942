<template>
  <div class="book-info">
    <div class="categories">
      <span class="category">{{ book.category }}</span>
      <span class="divider">·</span>
      <span class="history">{{ book.subcategory }}</span>
    </div>

    <h1 class="book-title">{{ book.title }}</h1>
    <p class="book-description">{{ book.description }}</p>

    <BookMetaInfo :book="book" />
    <BookOptionsSection :book="book" />
  </div>
</template>

<script>
import BookMetaInfo from './BookMetaInfo.vue'
import BookOptionsSection from './BookOptionsSection.vue'

export default {
  name: 'BookSummary',
  components: {
    BookMetaInfo,
    BookOptionsSection
  },
  props: {
    book: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.book-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.categories {
  color: #666;
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
}

.divider {
  color: #ccc;
}

.book-title {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.book-description {
  color: #666;
  line-height: 1.8;
  margin: 0;
}
</style>
