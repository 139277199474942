<template>
  <div class="main-container">
    <HeroSection v-model:searchQuery="searchQuery" v-model:searchAuthor="searchAuthor" v-model:searchYear="searchYear"
      :years="years" @search="handleSearch" />

    <WelcomeBanner />

    <FeaturedBooks :books="featuredBooks" @add-to-cart="addToCart" />

    <NewBookSection :books="newBooks" @add-to-cart="addToCart" />

    <HowItWorks :steps="howItWorks" />

    <AuthorsSection :authors="favoriteAuthors" />

    <FaqSection :faqs="faqs" :activeIndex="activeFaq" @toggle="toggleFaq" />

    <DailyQuoteSection />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import api from '@/api/axios'

import HeroSection from '@/components/home/HeroSection.vue'
import WelcomeBanner from '@/components/home/WelcomeBanner.vue'
import FeaturedBooks from '@/components/home/FeaturedBooks.vue'
import HowItWorks from '@/components/home/HowItWorks.vue'
import AuthorsSection from '@/components/home/AuthorsSection.vue'
import FaqSection from '@/components/home/FaqSection.vue'
import DailyQuoteSection from '@/components/home/DailyQuoteSection.vue'
import NewBookSection from '@/components/home/NewBookSection.vue'

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    WelcomeBanner,
    FeaturedBooks,
    NewBookSection,
    HowItWorks,
    AuthorsSection,
    FaqSection,
    DailyQuoteSection,
  },

  setup() {
    const router = useRouter()
    const store = useStore()

    // Reactive state
    const featuredBooks = ref([])
    const newBooks = ref([])
    const favoriteAuthors = ref([])
    const loading = ref(false)
    const searchQuery = ref('')
    const searchAuthor = ref('')
    const searchYear = ref('')
    const newsletterEmail = ref('')
    const activeFaq = ref(null)


    const howItWorks = ref([
      { title: 'Sign Up/Sign In', icon: 'fas fa-user' },
      { title: 'Pick the Book', icon: 'fas fa-book' },
      { title: 'Add to Cart', icon: 'fas fa-shopping-cart' },
      { title: 'Buy/Rent', icon: 'fas fa-hand-holding-usd' },
      { title: 'Pay the Bill', icon: 'fas fa-credit-card' },
      { title: 'Delivery', icon: 'fas fa-truck' },
    ])


    const faqs = ref([
      {
        question: 'How can I purchase a book at Booksville?',
        answer:
          'To purchase a book at Booksville, simply search for the book you want using the search bar or browse through the categories on the website. Once you find the book you want, select the format and quantity, add it to your shopping cart and proceed to checkout.',
      },
      {
        question: 'What payment methods does Booksville provide?',
        answer:
          'We accept various payment methods including credit/debit cards, PayPal, and more.',
      },
      {
        question: 'How long does it usually take for delivery after ordering?',
        answer:
          'Delivery times vary based on your location and chosen shipping method.',
      },
    ])

    // Computed properties
    const years = computed(() => {
      const currentYear = new Date().getFullYear()
      return Array.from({ length: 50 }, (_, i) => currentYear - i)
    })

    // API calls
    const fetchBooks = async () => {
      try {
        loading.value = true
        const response = await api.get('/books/')
        const newBooksResponse = await api.get('/books/new')

        if (response.data.status === 'success') {
          const books = response.data.results['book(s)'] || []
          featuredBooks.value = books
        }

        if (newBooksResponse.data.status === 'success') {
          newBooks.value = newBooksResponse.data.results.books || []
        }
      } catch (error) {
        console.error('Error fetching books:', error)
        store.dispatch('setError', 'Failed to load books')
      } finally {
        loading.value = false
      }
    }

    const fetchAuthors = async () => {
      try {
        const response = await api.get('/authors/')
        if (response.data.success) {
          favoriteAuthors.value = response.data.data || []
        }
      } catch (error) {
        console.error('Error fetching authors:', error)
      }
    }
    // Methods

    const handleSearch = () => {
      fetchBooks()
    }

    const addToCart = async book => {
      if (!store.getters.isAuthenticated) {
        router.push({
          name: 'login',
          query: { redirect: router.currentRoute.value.fullPath },
        })
        return
      }

      try {
        loading.value = true
        const response = await api.post(`/cart/${book.id}/add`, {
          purchase_type: 'buy',
        })

        if (response.data.success) {
          store.dispatch('setSuccess', 'Book added to cart successfully')
          router.push('/cart')
        }
      } catch (error) {
        if (error.response?.status === 401) {
          store.dispatch('logout')
          router.push({
            name: 'login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
        } else {
          store.dispatch(
            'setError',
            error.response?.data?.message || 'Failed to add book to cart'
          )
        }
      } finally {
        loading.value = false
      }
    }

    const toggleFaq = index => {
      activeFaq.value = activeFaq.value === index ? null : index
    }

    const subscribeNewsletter = async () => {
      if (!newsletterEmail.value) return

      try {
        const response = await api.post('/newsletter/subscribe', {
          email: newsletterEmail.value,
        })

        if (response.data.success) {
          newsletterEmail.value = ''
          store.dispatch('setSuccess', 'Successfully subscribed to newsletter!')
        }
      } catch (error) {
        store.dispatch('setError', 'Failed to subscribe to newsletter')
        console.error('Newsletter subscription error:', error)
      }
    }


    // Lifecycle hooks
    onMounted(async () => {
      await Promise.all([fetchBooks(), fetchAuthors()])
    })

    return {
      // State
      featuredBooks,
      newBooks,
      favoriteAuthors,
      howItWorks,
      faqs,
      activeFaq,
      loading,
      searchQuery,
      searchAuthor,
      searchYear,
      newsletterEmail,
      years,
      // Methods
      handleSearch,
      addToCart,
      toggleFaq,
      subscribeNewsletter,
    }
  },
}
</script>

<style scoped>
.main-container {
  margin: 0 auto;
}
</style>
