<template>
  <div class="book-details-container">
    <BreadcrumbNav v-if="book?.title" :bookTitle="book.title" />

    <div v-if="loading" class="loading">
      <div class="loading-spinner"></div>
      <span>Loading book details...</span>
    </div>

    <template v-else-if="book">
      <div class="book-content">
        <BookGallery :imageUrl="book.cover_image" :bookTitle="book.title" :isBestSeller="isBestSeller(book)" />

        <div class="book-info">
          <BookSummary :book="book" />
        </div>
      </div>

      <div class="book-tabs-section">
        <div class="tabs">
          <button v-for="tab in tabs" :key="tab.id" @click="activeTab = tab.id"
            :class="['tab-btn', { active: activeTab === tab.id }]">
            {{ tab.name }}
          </button>
        </div>

        <div class="tab-content">
          <DescriptionTab v-if="activeTab === 'description'" :book="book" />
          <ProductTab v-if="activeTab === 'product'" :book="book" />
          <ReviewTab v-if="activeTab === 'review'" :book="book" @review-submitted="handleReviewSubmitted" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/api/axios'

import BreadcrumbNav from '@/components/book/BreadcrumbNav.vue'
import BookGallery from '@/components/book/BookGallery.vue'
import BookSummary from '@/components/book/BookSummary.vue'
import DescriptionTab from '@/components/book/tabs/DescriptionTab.vue'
import ProductTab from '@/components/book/tabs/ProductTab.vue'
import ReviewTab from '@/components/book/tabs/ReviewTab.vue'

export default defineComponent({
  name: 'BookDetails',
  components: {
    BreadcrumbNav,
    BookGallery,
    BookSummary,
    DescriptionTab,
    ProductTab,
    ReviewTab,
  },
  setup() {
    const route = useRoute()
    const book = ref(null)
    const loading = ref(true)
    const activeTab = ref('description')
    const bookMetaRef = ref(null)

    const tabs = [
      { id: 'description', name: 'Description' },
      { id: 'product', name: 'Product' },
      { id: 'review', name: 'Review' },
    ]

    const fetchBookDetails = async () => {
      try {
        loading.value = true
        const response = await api.get(`/books/${route.params.id}/`)
        if (response.data.success) {
          book.value = response.data.book
          if (!book.value.reviews) {
            book.value.reviews = []
          }
        }
      } catch (error) {
        console.error('Error fetching book details:', error)
      } finally {
        loading.value = false
      }
    }

    const handleReviewSubmitted = async () => {
      await fetchBookDetails()
    }

    const isBestSeller = book => {
      if (!book) return false
      return book.sales_count > 1000 || book.rating >= 4.5
    }

    const formatDate = date => {
      if (!date) return ''
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    }

    onMounted(() => {
      fetchBookDetails()
    })

    return {
      book,
      loading,
      activeTab,
      tabs,
      bookMetaRef,
      isBestSeller,
      formatDate,
      handleReviewSubmitted,
    }
  },
})
</script>

<style scoped>
.book-details-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f6f7fb;
}

.book-content {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 4rem;
  margin-bottom: 4rem;
  background: white;
  border-radius: 12px;
  padding: 2rem;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background: white;
  border-radius: 12px;
  gap: 1rem;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f27cf9;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Tabs Section */
.book-tabs-section {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 4rem;
  width: 100%;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  border-bottom: 1px solid #eee;
  background: white;
}

.tab-btn {
  padding: 1.5rem;
  background: none;
  border: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  position: relative;
  text-align: center;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.tab-btn.active {
  color: #2b2668;
  font-weight: 500;
}

.tab-btn.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #2b2668;
}

.tab-content {
  padding: 2rem;
  min-height: 400px;
}

@media (max-width: 1200px) {
  .book-details-container {
    padding: 1rem;
  }

  .book-content {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .tab-btn {
    padding: 1rem 0.5rem;
    font-size: 0.95rem;
  }

  .tab-content {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .book-details-container {
    padding: 0.5rem;
  }

  .book-content {
    padding: 1rem;
    gap: 1.5rem;
  }

  .tab-btn {
    padding: 0.75rem 0.25rem;
    font-size: 0.9rem;
  }

  .tab-content {
    padding: 1rem;
  }
}
</style>
